<div class="rounded-lg">
    <p class="text-4xl mb-6">Busca Avançada</p>
    <div class="filter">
        <form class="filter-form" [formGroup]="auctionSearchForm">
            
            <div class="slider-container">
                <mat-label class="labelValue">Valor: {{ rangeValues() }}</mat-label>
                <mat-slider min="0" max="2300000" style="width: 18vw;">
                    <input matSliderStartThumb [formControlName]="'range1'">
                    <input matSliderEndThumb formControlName="range2">
                </mat-slider>
            </div>

            <mat-form-field>
                <mat-label>Cidade</mat-label>
                <mat-select formControlName="city">
                    @for (city of cities; track city) {
                    <mat-option [value]="city.value">{{city.name}}</mat-option>
                    }
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Bairro</mat-label>
                <mat-select formControlName="neighborhood">
                    @for (neighborhood of neighborhoods; track neighborhood) {
                    <mat-option [value]="neighborhood.value">{{neighborhood.name}}</mat-option>
                    }
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Perfil do imóvel</mat-label>
                <mat-select formControlName="profile">
                    <mat-option [value]="0">Comercial</mat-option>
                    <mat-option [value]="1">Residencial</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Situação</mat-label>
                <mat-select formControlName="status">
                    <mat-option [value]="0">Ocupado</mat-option>
                    <mat-option [value]="1">Desocupado</mat-option>
                </mat-select>
            </mat-form-field>

            <button mat-fab color="primary" class="mr-3 button-test" (click)="search(buttonSearch)" #buttonSearch>
                <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="search"></mat-icon>
            </button>
        </form>
    </div>
</div>
