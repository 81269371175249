<div #card class="bg-white/75 shadow-md rounded-lg card flex flex-col">
    <div class="p-4">
        <img class="image" style="border-radius: 5px" 
            [src]="auctionItem()?.urlImages[0]"/>
    </div>
    <div class="p-6 relative">
        <nav class="pb-2">
            <p matTooltip="auctionItem()?.nome" class="title text-wrap truncate line-clamp-2 title">{{auctionItem()?.nome}}</p>
        </nav>
        <span class="city pb-2">{{auctionItem()?.nomCidade}}</span>
        <div class="mt-3 flex flex-row gap-2 bags">
            <span class="bag rounded"
                [ngClass]="{'bag-desocupado': auctionItem()?.status !== 'Ocupado', 'bag-ocupado': auctionItem()?.status === 'Ocupado'}"><p>{{auctionItem()?.status}}</p></span>

            <span class="bag rounded"
                [ngClass]="{'bag-comercial': auctionItem()?.nomTipoImovel === 'Comercial', 'bag-residencial': auctionItem()?.nomTipoImovel !== 'Comercial'}"><p>{{auctionItem()?.nomTipoImovel }}</p></span>
        </div>

        <div skeleton class="skeleton-description"></div>
        <div skeleton class="skeleton-subdescription"></div>
        <div skeleton class="skeleton-bag1"></div>
        <div skeleton class="skeleton-bag2"></div>
    </div>
    <div class="last-item-card p-6 relative">
        <section class="flex">
            <div class="flex flex-col w-1/2 auction-date-value-infos">
                <span class="auction-date">{{auctionItem()?.dataLeilao[0] | date: 'dd/MM/yyyy - HH:mm'}}</span>
                <span class="auction-value">{{auctionItem()?.avaliacao | currency: 'BRL'}}</span>
            </div>
            <div class="ml-6 w-1/2 border-l-indigo-500 auction-percentage-box">
                <svg *ngIf="auctionItem()" width="16" height="27" viewBox="0 0 16 27" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M8 21.7092L2.15407 15.8633L0 18.0174L8 26.0174L16 18.0174L13.8459 15.8633L8 21.7092Z" fill="#31B026"></path><rect x="6.50391" width="3.04632" height="22.9794" fill="#31B026"></rect></svg>
                <span *ngIf="auctionItem()" class="auction-percentage">{{auctionItem()?.percentualEconomia}}%</span>
            </div>
        </section>  
        
        <section>
            <button class="auction-details">Ver detalhes</button>
        </section>

        <div skeleton class="skeleton-auction-date"></div>
        <div skeleton class="skeleton-auction-value"></div>
        <div skeleton class="skeleton-auction-percentage"></div>
        <div skeleton class="skeleton-btn-details"></div>
    </div>
</div>