import { Component, ElementRef, ViewChild, effect, input } from '@angular/core';

@Component({
  selector: 'app-auction-search-list',
  templateUrl: './auction-search-list.component.html',
  styleUrl: './auction-search-list.component.scss'
})
export class AuctionSearchListComponent {

  @ViewChild('list') list: ElementRef<any>;
  auctionItens = input<any[]>();
  typeExibition = input<number>();

  constructor() {
    effect(() => {
      if(this.typeExibition() === 0){
        this.list.nativeElement.removeAttribute('lista');
      } else {
        this.list.nativeElement.setAttributeNode(document.createAttribute('lista'));
      }
    })
  }
}
