import { AfterViewInit, Component, inject, signal } from '@angular/core';
import { AuctionSearchService } from 'app/shared/api/auction-search/auction-search.service';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, ParamMap, Params } from '@angular/router';
import { FilterDto, RealState } from 'app/shared/api/auction-search/models/real-estate.model';
import { parseToNumberValues } from 'app/shared/functions';
import RetornoPaginado from 'app/shared/api/common/retorno-paginado.model';
import { delay } from 'rxjs';

@Component({
  selector: 'app-auction-search',
  templateUrl: './auction-search.component.html',
  styleUrls: ['./auction-search.component.sass']
})
export class AuctionSearchComponent implements AfterViewInit{

  typeExibition = signal(0);
  paginatedResult: RetornoPaginado<any>;
  auctionItens:any[] = [];
  queryParams: FilterDto =  {
    range1: 50_000,
    range2: 550_000,
    city: 0,
    page: 0,
    pageSize: 10
  };
  
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _service: AuctionSearchService) {
    
    _activatedRoute.queryParamMap.subscribe(
      (params:ParamMap) => {
        if(params.keys.length > 0) {
          this.queryParams = parseToNumberValues({..._activatedRoute.snapshot.queryParams}) as FilterDto;
        }
        this.onFilterEvent(this.queryParams);
      }
    )
  }
  
  ngAfterViewInit(): void {
    this.typeExibition.set(Number(localStorage.getItem('tipo_exibicao') ?? '0')); 
    this.onToggleChange(localStorage.getItem('tipo_exibicao') === '1');
  }

  onToggleChange($event: boolean) {
    this.typeExibition.set($event ? 1 : 0);
  }

  onFilterEvent($event: FilterDto) {
    this._service.filterRealState($event)
    .pipe(delay(1000))
    .subscribe((result:RetornoPaginado<RealState>) => {
      this.paginatedResult = result;
      this.auctionItens = result.content ?? [];
    });
  }
}
