<div #list class="grid gap-12 justify-center card-container">
    <ng-container *ngFor="let item of auctionItens()">
        <app-auction-search-card [auctionItem]="item" [typeExibition]="typeExibition()"></app-auction-search-card>
    </ng-container>

    <ng-container *ngIf="auctionItens()?.length === 0">
        <app-auction-search-card [typeExibition]="typeExibition()"></app-auction-search-card>
        <app-auction-search-card [typeExibition]="typeExibition()"></app-auction-search-card>
        <app-auction-search-card [typeExibition]="typeExibition()"></app-auction-search-card>
        <app-auction-search-card [typeExibition]="typeExibition()"></app-auction-search-card>
        <!-- <p class="not-found-text">
            Não encontramos nenhum resultado no momento, entre em contato para mais informações!
        </p> -->
    </ng-container>
</div>