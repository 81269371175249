import { Component, ElementRef, ViewChild, effect, input, viewChild } from '@angular/core';

@Component({
  selector: 'app-auction-search-card',
  templateUrl: './auction-search-card.component.html',
  styleUrls: ['./auction-search-card.component.scss']
})
export class AuctionSearchCardComponent {
  @ViewChild('card') card: ElementRef<any>;

  auctionItem = input<any>();
  typeExibition = input<number>();

  constructor() {
    effect(() => {
      if(this.typeExibition() === 0) {
        this.card.nativeElement.removeAttribute('lista');
      } else {
        this.card.nativeElement.setAttributeNode(document.createAttribute('lista'));
      }

      const skeletons = document.querySelectorAll<any>('[skeleton]');
      if(this.auctionItem()) {
        skeletons.forEach(item => {
          item.style.display = 'none';
        });
      } else {
        skeletons.forEach(item => {
          item.style.display = 'block';
        });
      }
    });
  }
}
