
export const environment = {
	PUBLIC_API_URL: "https://localhost:5001/",
	gateway: {
		auction_search: {
			baseUrl: 'api/ad/v1/',
		},
		sign_up: {
			baseUrl: "api/clientes"
		}
	},
	production: false,
};
