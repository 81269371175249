import { CurrencyPipe } from '@angular/common';
import { Component, effect, inject, input, output } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { FilterDto } from 'app/shared/api/auction-search/models/real-estate.model';

interface OptionValue {
  value: number;
  name: string;
}

@Component({
  selector: 'app-auction-search-filter',
  templateUrl: './auction-search-filter.component.html',
  styleUrls: ['./auction-search-filter.component.scss']
})
export class AuctionSearchFilterComponent {

  
  queryParams = input<FilterDto>();
  searchClickEvent = output<FilterDto>();
  
  
  cities: OptionValue[] = [
    {value: 0, name: 'Curitiba'},
    {value: 1, name: 'São José dos Pinhais'},
    {value: 2, name: 'Colombo'},
    ];
  neighborhoods: OptionValue[] = [];
    
    
  private _formBuilder = inject(FormBuilder); 
  auctionSearchForm: UntypedFormGroup;

  constructor(private _currencyPipe: CurrencyPipe) {
    effect(() => {
        this.auctionSearchForm.patchValue(this.queryParams() as FilterDto);
    }, {allowSignalWrites: true})  
  }

  ngOnInit() {
    this.auctionSearchForm = this._formBuilder.group({
      range1: [200000, Validators.min(0)],
      range2: [950000, Validators.min(3_000_000)],
      city: [null],
      neighborhood: [null],
      profile: [null],
      status: [null],
    })
  }

  search(buttonSearch: MatButton): void {
    buttonSearch._elementRef.nativeElement.getElementsByTagName('mat-icon')[0].setAttribute("fonticon", "cached")
    buttonSearch._elementRef.nativeElement.getElementsByTagName('mat-icon')[0].classList.add('rotate');

    this.searchClickEvent.emit(this.auctionSearchForm.value as FilterDto);
    console.log('searchClickEvent');
    // setTimeout(() => {
    //   buttonSearch._elementRef.nativeElement.getElementsByTagName('mat-icon')[0].classList.remove('rotate');  
    //   buttonSearch._elementRef.nativeElement.getElementsByTagName('mat-icon')[0].setAttribute("fonticon", "search")
    // }, 1000)

  }

  rangeValues(): string {
    return new String().concat(this._currencyPipe.transform(this.auctionSearchForm.get('range1')?.value, 'BRL')!, ' - ', 
    this._currencyPipe.transform(this.auctionSearchForm.get('range2')?.value, 'BRL')!);
  }
}
